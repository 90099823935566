.home {
  margin-top: 0px;
  padding: 2rem 4rem;
  @media (max-width: 1023.99px) {
    padding: 2rem 0rem;
  }

  h1 {
    margin: 0px 16px;
    text-align: left;
    font-size: 48px;
    @media (max-width: 1023.99px) {
      font-size: 32px;
    }

    .emphasis {
      font-style: italic;
      color: aquamarine;
      .transition-text {
        display: inline-flex;
        width: 193px;
        @media (max-width: 1023.99px) {
          width: 129px;
        }
      }
    }
  }
  h2 {
    text-align: left;
    color: #94a2a2;
    margin: 0px 16px;
    font-style: italic;
    font-weight: normal;
    font-size: 22px;
  }
}