.contact-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;

  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
  }

  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .contact-form {
      flex: 1;
      margin-right: 20px;

      .form-group {
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
        }

        input,
        textarea {
          width: 100%;
          padding: 10px;
          border: none;
          background-color: #ffffff20;
          border-radius: 4px;
          font-size: 1em;
          outline: none;
          color: white;
        }

        textarea {
          height: 100px;
          resize: vertical;
        }
      }
      .action-wrapper {
        display: flex;
        .submit-button {
          display: block;
          padding: 10px 20px;
          background-color: transparent;
          color: aquamarine;
          border: 1px solid aquamarine;
          border-radius: 4px;
          cursor: pointer;
          font-size: 1em;
  
          &:hover {
            background-color: #2980b9;
          }
        }
        .success-message {
          margin: 0px 20px;
          font-style: italic;
          color: aquamarine;
        }
        .error-message {
          display: block;
          width: 75%;
          margin: 0 0 0 20px;
          color: #cd0a0a;
          a {
            color: #ff2d2d;
          }
        }
      }
    }
  }
}
