.App {
  text-align: center;
  background-color: #1b1e25;

  main {
    margin: 0px 6rem;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    @media (min-width: 2100px) {
      margin: 0px auto;
      max-width: 1800px;
    }
    @media (max-width: 1023.99px) {
      margin: 0 88px 0 0;
    }
  }
}