.portfolio {
  text-align: center;
  padding: 20px;

  h2 {
    margin: 0 0 24px 0;
  }
  .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .project-card {
    background-color: #3b3e41;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    width: 300px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    h3 {
      margin-top: 0;
    }

    p {
      color: #ffffff;
    }

    .project-links {
      margin-top: 10px;

      a, .unavailable {
        text-decoration: none;
        color: aquamarine;
        font-weight: 600;
        margin: 0 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
