.resume {
  padding: 1rem 4rem;
  @media (max-width: 1023.99px) {
    padding: 1rem 0rem;
  }
}

.contact-card {
  margin: 0 16px 24px 16px;
  padding: 12px;
  border-radius: 8px;
  background-color: #3b3e41;
  display: flex;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  @media (max-width: 767.99px) {
    margin: 0 4px 24px 4px;
    flex-direction: column;
    align-items: center;
  }
  .contact-content {
    width: 60%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 767.99px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 6px;
      h1 {
        display: flex;
        flex-direction: column;
        font-size: 24px;
      }
      span {
        margin: 0;
        text-align: center;
      }
    }
  }
  .profile {
    margin: 0px 24px;
    width: 20%;
    .profile-picture {
      width: 100%;
      height: auto;
      border-radius: 50%;
      border: 6px aquamarine double;
      @media (max-width: 767.99px) {
        width: auto;
        height: 200px
      }
    }
    @media (max-width: 767.99px) {
      width: 100%;
      height: 200px
    }
  }
  .links {
    margin: auto 1rem;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media (max-width: 767.99px) {
      margin: 1rem;
    }
    a {
      color: aquamarine;
      font-weight: 600;
      text-decoration: none;
      font-size: 18px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h1 {
    margin: 0;

    span {
      margin-left: 6px;
      font-size: 16px;
      color :#b2b2b2
    }
  }
  .contact-email {
    color: #5EB5F7;
  }
}

.showcase {
  margin: 0 16px 24px 16px;
  padding: 0 12px 12px 12px;
  border-radius: 8px;
  text-align: left;
  background-color: #3b3e41;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 767.99px) {
    margin: 0 4px 24px 4px;
  }

  h2 {
    margin: 0 0 8px 0;
  }
  .card-wrapper {
    display: flex;
    gap: 16px;
    @media (max-width: 767.99px) {
      flex-direction: column;
      .tool-card {
        min-height: 200px;
        width: 100%;
      }
    }
    .tool-card {
      height: 300px;
      border-radius: 6px;
      border: 1px solid #676767;
      display: flex;
      align-items: end;
      flex: 1;
      color: #ffffff;
      text-decoration: none;

      .caption {
        width: fit-content;
        padding: 2px 6px;
        margin: 0px;
        border-radius: 0px 6px 0px 6px;
        background-color: #606870;
        color: #ffffff;
        font-weight: 600;
        font-size: 17px;
        letter-spacing: 0.25px;
      }

      &.color-gen {
        background-image: url("/assets/images/color-gen.webp");
        background-size: cover;
        background-position: top;
      }
      &.json-formatter {
        background-image: url("/assets/images/json-formatter.png");
        background-size: cover;
        background-position: top;
      }
      &.accessibility-tool {
        background-image: url("/assets/images/accessibility-tool.png");
        background-size: cover;
        background-position: top;
      }
    }
  }
}

.education {
  margin: 0 16px 24px 16px;
  padding: 12px;
  border-radius: 8px;
  text-align: left;
  background-color: #3b3e41;
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 767.99px) {
    margin: 0 4px 24px 4px;
  }
  h2 {
    margin: 0;
    width: 100%;
  }

  p {
    margin: 0 0 0px 6px;
    line-height: 1.6;
    color: #bfbfbf;
  }
}

.resume-section {
  margin: 0 16px 24px 16px;
  padding: 12px;
  border-radius: 8px;
  text-align: left;
  background-color: #3b3e41;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 767.99px) {
    margin: 0 4px 24px 4px;
  }

  h2 {
    margin: 0 0 8px 0;
  }

  strong {
    color: #bfbfbf;
  }

  h3 {
    width: fit-content;
    font-size: 24px;
    margin: 0 0 8px 0;
  }

  ul {
    list-style: none;
    padding: 12px 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 48px;
    background-color: #484c51;
    border-radius: 8px;
    transition: all 1s ease;
    row-gap: 24px;

    &:hover {
      @media(min-width: 1023.999px) {
        background-color: aquamarine;
  
        li {
          color: #6c6c6c;
          .stat {
            opacity: 0;
            height: 0;
          }
          .emote {
            font-size: 48px;
            height: 70px;
            opacity: 1;
          }
        }
      }
    }
    &.transition {
      @media(max-width: 1023.99px) {
        background-color: aquamarine;
  
        li {
          color: #6c6c6c;
          .stat {
            opacity: 0;
            height: 0;
          }
          .emote {
            font-size: 48px;
            height: 70px;
            opacity: 1;
          }
        }
      }
    }

    li {
      min-width: 88px;
      flex: 1;
      .stat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        height: 70px;
        opacity: 1;
        transition: all 1s ease;
      }
      .emote {
        display: flex;
        justify-content: center;
        justify-self: center;
        opacity: 0;
        height: 0;
        transition: all 1s ease;
      }

      .fab {
        font-size: 48px;
      }
    }
  }
}
