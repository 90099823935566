.accessibility-tool {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  @media (max-width: 1023.99px) {
    margin: 0 4px;
    text-align: center;
  }

  h2 {
    text-align: center;
    padding: 16px 0;
  }

  textarea {
    width: 96%;
    height: 120px;
    padding: 12px 4px;
    background-color: #ffffff20;
    border-radius: 6px;
    border: none;
    resize: vertical;
    color: #ffffff;
    outline: none;

    &:disabled {
      color: aquamarine;
    }
  }

  button {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: transparent;
    color: aquamarine;
    border: 1px solid aquamarine;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0000005f;
    }
  }

  .accessibility-form {
    margin-top: 20px;

    .element-form {
      margin-bottom: 20px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;

      h4 {
        margin-bottom: 10px;
        color: aquamarine;
      }

      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;

        input {
          width: 100%;
          padding: 8px;
          margin-top: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
  }

  .output {
    margin-top: 20px;

    textarea {
      width: 96%;
      height: 120px;
      padding: 12px 4px;
      background-color: #ffffff20;
      border-radius: 6px;
      border: none;
      resize: vertical;
      color: #ffffff;
      outline: none;
  
      &:disabled {
        color: aquamarine;
      }
    }
  }
}
