h2 {
  margin: 0;
  padding: 16px 0 0 0;
}

.content {
  display: flex;
  padding: 24px 8px 8px 8px;
  gap: 4px;
  @media (max-width: 767.99px) {
    flex-direction: column;
  }

  .pane {
    width: 200px;
    padding: 8px;

    @media (max-width: 1023.99px) {
      width: 124px;
    }
    @media (max-width: 767.99px) {
      width: calc(100% - 16px);
    }
  }
  .action {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    @media(max-width: 767.99px) {
      flex-direction: column-reverse;
    }
    .button-group {
      margin-bottom: 340px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media(max-width: 767.99px) {
        margin: 0;
      }
    }
  }
  .read {
    flex: 1;
    display: flex;
    text-align: left;
  }
  .write {
    flex: 1;
    display: flex;
  }
  textarea {
    width: 100%;
    height: 80vh;
    padding: 12px 4px;
    background-color: #ffffff20;
    border-radius: 6px;
    border: none;
    resize: vertical;
    color: #ffffff;
    outline: none;

    @media (max-width: 767.99px) {
      height: 40vh;
    }

    &:disabled {
      color: aquamarine;
      text-wrap: nowrap;
    }
  }
  button {
    width: 120px;
    background-color: transparent;
    border: 2px solid aquamarine;
    color: aquamarine;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 14px;
    height: fit-content;
    letter-spacing: 0.25px;
    cursor: pointer;
  }
  .radio-group {
    margin-top: 1rem;
  }
  .radio-label {
    padding: 8px 12px;
    border: #777777 1px solid;
    background-color: #444444;
    cursor: pointer;
    &:first-of-type {
      border-radius: 6px 0 0 6px;
    }
    &:last-of-type {
      border-radius: 0 6px 6px 0;
    }
    &.checked {
      background-color: #343434;
      border-color: #555555;
    }
    .radio-button {
      display: none;
    }
  }
}

#json-pretty {
  width: 100%;
}