.mockup-preview {
  margin: 20px 8px;
  overflow: hidden;
  border-radius: 6px;
  min-height: 500px;
  display: flex;
  flex-direction: column;

  .mockup-header {
    padding: 20px;
    position: relative;
    text-align: center;
    z-index: 15;
    h1 {
      margin: 0;
    }
  }
  .mockup-main {
    display: flex;
    flex: 1;
    .mockup-aside {
      width: 200px;
      padding: 15px;
    }
    .mockup-section {
      padding: 20px;
      flex-grow: 1;
      .mockup-alert {
        padding: 12px;
        border-radius: 4px;
        border: 1px solid;
        span {
          padding: 4px;
          font-size: 14px;
        }
        b {
          margin: 0;
        }
      }
      .mockup-panel {
        padding: 20px;
        margin: 10px 0px;
        border-radius: 4px;

        .button {
          margin: 2px 8px;
          padding: 8px;
          width: 120px;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          border: 2px solid;
          cursor: pointer;
          &.mockup-submit {
            border: none;
          }
          &.mockup-cancel {
            background-color: transparent;
          }
        }
      }
    }
  }
}