.color-page {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
}

.color-palette-generator {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  h2 {
    margin: 0px 0px 24px 0px;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;

    .color-options {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 10px;

      label {
        margin: 5px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border: 2px solid transparent;
        border-radius: 50%;

        &:hover {
          border-color: #555;
        }

        input[type="radio"] {
          display: none;
        }

        .color-preview {
          position: relative;
          display: block;
          height: 42px;
          width: 42px;
          top: -3px;
          left: -3px;
          border-radius: 50%;
        }
        input[type="radio"]:checked + .color-preview {
          border: 2px solid white;
        }
      }
    }

    .theme-options {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      label {
        margin: 0 10px;
        cursor: pointer;

        input[type="radio"] {
          margin-right: 5px;
        }
      }
    }

    button {
      padding: 8px 15px;
      background-color: transparent;
      color: aquamarine;
      border: 1px solid aquamarine;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #2980b9;
      }
    }
  }

  .palette {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .color-box {
      width: 120px;
      height: 120px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      color: #333;
      font-weight: bold;
      text-align: center;

      p {
        margin: 0;
        padding: 5px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 4px;
      }

      p:first-child {
        margin-bottom: 5px;
        font-size: 12px;
      }
    }
  }
}
